import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { ListItem } from "../components/common/lists"

  // ul: props => {
  //   console.log("props", props)
  //   return (
  //     <ul>
  //     {
  //       /*console.log("props.children", props.children) */
  //       props.children.map((child) => {
  //         {/*console.log("child.props", child.props)*/}
  //         {
  //           <li>
  //             {child.props.children}
  //             </li>
  //         }
  //       })
  //     }
  //     </ul>
  //   )
  // }

const components = {
  h1: props => <h1 style={{ color: "tomato" }} {...props} />,
  h2: ({children}) => {
    // console.log("h2.children",children)
    return (
      <h2 style={{ color: "saddlebrown", marginBottom: `10px` }} > {children} </h2>
    )
  },
  // h2: H2,
  h3: ({children}) => {
    return (
      <h3 style={{ color: "dimgray", textAlign: "center", marginBottom: `10px` }} > {children} </h3>
    )
  },
  ul: props => {
    // console.log(props)
    const items = props.children.map(child => child.props.children)
    return (
      <ListItem items={items} />
    )
  }
}

const BlogPostTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next, allReference } = data
  // console.log("data@BlogPostTemplate", data)
  const post = data.mdx
  const { frontmatter, excerpt } = data.mdx

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={frontmatter.title}
        description={frontmatter.description || excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{frontmatter.title}</h1>
          <p style={{textAlign: "right"}}>{frontmatter.date}</p>
        </header>
        
        <MDXProvider components={components}>
          <MDXRenderer 
            localImages={frontmatter.embeddedImagesLocal} 
            references={allReference}
            itemProp="articleBody"
          >
            {post.body}
          </MDXRenderer>
        </MDXProvider>

        <hr />
        <footer> </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "YYYY年MM月DD日")
        description
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      tableOfContents
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    allReference {
      edges {
        node {
          key
          title
          authors
          journal
          date
          publicurl
          date
        }
      }
    }
  }
`
