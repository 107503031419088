/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

const Seo = ({ description, title, ogpType, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            social {
              github
            }
          }
        }
      }
    `
  )
  const { pathname } = useLocation()
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = title || site.siteMetadata?.title
  const siteUrl = site.siteMetadata.siteUrl
  const type = ogpType || "website"

  return (
    <>
      {/*langを削除*/}
      <title>{defaultTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={defaultTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content={type} /> 
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:url" content={`${siteUrl}${pathname}`} />
    {/*<meta property="og:image" content={`${site.siteMetadata?.siteUrl}${imgPath}`} /> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={site.siteMetadata?.social?.twitter || ``} />
      <meta name="twitter:title" content={defaultTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto+Sans+JP:wght@400;700&display=swap" />
      {children}
    </>
  )
}

Seo.defaultProps = {
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
